import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

// images
import img1 from "assets/images/works/img_f1.jpeg";
import img2 from "assets/images/works/img_16.jpg";
import img3 from "assets/images/works/img_17.jpg";
import img4 from "assets/images/works/img_20.jpg";
import img5 from "assets/images/works/img_1.jpg";
import img6 from "assets/images/works/Img_f7.jpg";
import img7 from "assets/images/works/img_f8.jpeg";
import img8 from "assets/images/works/img_f9.jpeg";
import img9 from "assets/images/works/img_14.jpg";
import img10 from "assets/images/works/img_f11.jpeg";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}
export default function QuiltedImageList() {
  return (
    <ImageList sx={{ width: "100%" }} variant="quilted" cols={4} rowHeight={121}>
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            style={{
              padding: "2px",
              borderRadius: "5px",
              boxShadow:
                "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.1)",
            }}
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
const itemData = [
  {
    img: img1,
    title: "product1",
    cols: 2,
    rows: 2,
  },
  {
    img: img2,
    title: "product2",
  },
  {
    img: img4,
    title: "product4",
    rows: 2,
  },
  {
    img: img3,
    title: "product3",
  },
  {
    img: img5,
    title: "product5",
  },
  {
    img: img6,
    title: "product6",
    rows: 2,
  },
  {
    img: img7,
    title: "product7",
  },
  {
    img: img8,
    title: "product8",
  },
  {
    img: img9,
    title: "product9",
  },
  {
    img: img10,
    title: "product10",
    cols: 2,
  },
];
