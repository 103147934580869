// @mui material components
import Icon from "@mui/material/Icon";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// Routes
import routes from "routes";
function Header() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          icon: <Icon>call_icon</Icon>,
          route: "/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
      />
    </>
  );
}
export default Header;
