// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/contact_us_side_2.jpg";

function ContactCard() {
  return (
    <MKBox component="section" py={{ xs: 4, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Contact Information
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Fill up the form and our Team will get back to you.
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        (+91) 8735966424
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        (+91) 8603911712
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        epmworks.offical@gmail.com
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        899/ 3B, G.I.D.C Industrial Estate, Makarpura, Vadodara - 390010, Gujarat,
                        India.
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                      <MKButton
                        component="a"
                        href="https://wa.me/918735966424"
                        target="_blank"
                        variant="text"
                        color="white"
                        size="large"
                        iconOnly
                      >
                        <i className="fab fa-whatsapp" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton
                        component="a"
                        href="mailto:epmworks.offical@gmail.com"
                        target="_blank"
                        variant="text"
                        color="white"
                        size="large"
                        iconOnly
                      >
                        <i className="far fa-envelope" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox
                  component="form"
                  p={2}
                  method="post"
                  action="https://formsubmit.co/vivoy8981@gmail.com"
                >
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Contact Us!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Discuss your specific project requirements and discover how Elite Precision
                      Machining Works can become your trusted partner in precision manufacturing.
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={4}>
                        <MKInput
                          variant="outlined"
                          type="text"
                          name="fullName"
                          label="Full Name"
                          placeholder="e.g John Doe"
                          fullWidth
                        />
                      </Grid>
                      <Grid container item xs={12} pr={1} mb={4}>
                        <Grid item xs={6} pr={1}>
                          <MKInput
                            variant="outlined"
                            type="email"
                            name="email"
                            label="Email"
                            placeholder="e.g epmworks.offical@gmail.com"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6} pr={1}>
                          <MKInput
                            variant="outlined"
                            type="tel"
                            name="mobile"
                            label="Mobile Number"
                            placeholder="e.g +91-873-596-6424"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} pr={1} mb={4}>
                        <MKInput
                          variant="outlined"
                          type="text"
                          name="subject"
                          label="Subject"
                          placeholder="e.g I'm looking for"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={4}>
                        <MKInput
                          variant="outlined"
                          type="text"
                          name="message"
                          label="Your Message"
                          placeholder="e.g I want to say that..."
                          fullWidth
                          multiline
                          rows={6}
                        />
                        <MKInput variant="standard" type="hidden" name="_captcha" value="false" />
                        <MKInput variant="standard" type="hidden" name="_template" value="table" />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton variant="gradient" color="info" type="submit">
                        Send Message
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactCard;
