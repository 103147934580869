// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import bgImage2 from "assets/images/about_us_2.jpg";
import bgImage3 from "assets/images/about_us_f3.jpg";

function About() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 6 }}>
      <Container>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <MKTypography
              component="p"
              variant="subtitle1"
              color="text"
              style={{ "text-align": "justify", "font-weight": "400" }}
              mb={2}
            >
              Elite Precision Machining Works is a leading provider of precision machining services,
              catering to the diverse needs of businesses across various industries. With over a
              decade of experience in the field, we have established a reputation for delivering
              exceptional quality, timely service, and unwavering commitment to customer
              satisfaction.
            </MKTypography>
            <MKTypography
              component="p"
              variant="subtitle1"
              color="text"
              style={{ "text-align": "justify", "font-weight": "400" }}
            >
              We are not just a machine shop; we are a trusted partner in your manufacturing
              success. We offer comprehensive services that extend beyond precision machining,
              including:
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={3} mt={3}>
          <Grid item xs={12} lg={7}>
            <MKTypography
              component="p"
              variant="h6"
              color="text"
              style={{ "text-align": "justify" }}
            >
              Design Consultation:
            </MKTypography>
            <MKTypography
              component="p"
              variant="body2"
              color="text"
              style={{ "text-align": "justify" }}
              py={2}
            >
              Our experienced engineers can provide valuable insights into design optimization for
              manufacturability, ensuring that your components are produced efficiently and
              cost-effectively.
            </MKTypography>
            <MKTypography
              component="p"
              variant="h6"
              color="text"
              style={{ "text-align": "justify" }}
            >
              Project Management:
            </MKTypography>
            <MKTypography
              component="p"
              variant="body2"
              color="text"
              style={{ "text-align": "justify" }}
              py={2}
            >
              We take hands-on approach to project management, keeping you informed every step of
              the way and ensuring that your projects are completed on time and within budget.
            </MKTypography>
            <MKTypography
              component="p"
              variant="h6"
              color="text"
              style={{ "text-align": "justify" }}
            >
              Quality Control:
            </MKTypography>
            <MKTypography
              component="p"
              variant="body2"
              color="text"
              style={{ "text-align": "justify" }}
              py={2}
            >
              Our stringent quality control procedures ensure that every component meets your
              specifications and exceeds your expectations.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={5}>
            <img
              src={bgImage2}
              height={400}
              width={400}
              style={{ borderRadius: "22px", margin: "5px" }}
            ></img>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={3} mt={3}>
          <Grid item xs={12} lg={5}>
            <img
              alt=""
              src={bgImage3}
              height={400}
              width={400}
              style={{ borderRadius: "22px", margin: "5px" }}
            ></img>
          </Grid>
          <Grid item xs={12} lg={7}>
            <MKTypography
              component="p"
              variant="h6"
              color="text"
              style={{ "text-align": "justify" }}
            >
              Packaging and Shipping:
            </MKTypography>
            <MKTypography
              component="p"
              variant="body2"
              color="text"
              style={{ "text-align": "justify" }}
              py={2}
            >
              We carefully package and ship your components to ensure they arrive safely and in
              pristine condition.
            </MKTypography>
            <MKTypography
              component="p"
              variant="h6"
              color="text"
              style={{ "text-align": "justify" }}
            >
              Testing and Inspection:
            </MKTypography>
            <MKTypography
              component="p"
              variant="body2"
              color="text"
              style={{ "text-align": "justify" }}
              py={2}
            >
              We perform rigorous testing and inspection procedures to verify the dimensional
              accuracy, surface finish, and functionality of your components.
            </MKTypography>
            <MKTypography
              component="p"
              variant="subtitle1"
              color="text"
              style={{ "text-align": "justify" }}
            >
              <strong></strong>
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default About;
