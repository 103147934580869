// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Images
import latheImg from "assets/images/lathe_machine.jpg";
import droImg from "assets/images/DRO_machine.jpg";
import cncImg from "assets/images/CNC_mahine.jpg";
import expertiseImg from "assets/images/experties.jpg";
import toleranceImg from "assets/images/tole.jpg";
import streamlinedImg from "assets/images/streamlined.jpg";

import CardWithImage from "../components/CardWithImage";

const data = [
  {
    id: 1,
    image: latheImg,
    heading: "Lathe Machine",
    description: `Versatile and reliable, or lathe machine is dept at handling a veriety of machining tasks with precision.`,
  },
  {
    id: 2,
    image: droImg,
    heading: "Digital Readout Machine",
    description: `Equipped with DRO technology, this machine enhance precision, providing real-time feedback for accurate machining.`,
  },
  {
    id: 3,
    image: cncImg,
    heading: "CNC Turning Machine",
    description: `Our CNC turning machine is at the forefront of efficiency and accuracy, catering to a diverse range of projects.`,
  },
  {
    id: 4,
    image: expertiseImg,
    heading: "Material Expertise",
    description: `We process an extensive range of materials, offering flexibility and adaptability to diverse project requirements. Our expertise includes but is not limited to:`,
    subDesc: [
      { text: "Metails: Stainless, Aluminum, Brass, Titanium, And specialized alloys.", type: "p" },
      {
        text: "Plastics: Nylon, Acrylic, Polycarbonate, and various engineering plastics.",
        type: "p",
      },
    ],
  },
  {
    id: 5,
    image: toleranceImg,
    heading: "Tight Tolerance",
    // description: `Precision is our hall mark. We consistenlly achieve tight tolerances, ensuring that every component meets or exceeds specified requirements.We are also able to work with a veriety of tolerances, including:+/- 0.005 mm, +/- 0.010 mm`,
    description: `Precision is our hall mark. We consistenlly achieve tight tolerances, ensuring that every component meets or exceeds specified requirements.`,
    subDesc: [
      { text: "We are also able to work with a veriety of tolerances, including:", type: "p" },
      { type: "ul", li: ["+/- 0.005 mm", "+/- 0.010 mm"] },
    ],
  },
  {
    id: 6,
    image: streamlinedImg,
    heading: "Streamlined Production",
    description: `EPMW is know for its expert production hendling, guaranteeing that every component meets the highest quality standards. Our skilled technicians meticulouly plan and execute each productio step, utilizing a streamlined process that seamlessly integrates out three key machining technologies (Lathe machines, DRO machines and CNC machines). This comprehensive approach enables us to handle a wide range of job requirements, from intricate to complex, with exceptional precision and efficiency. Moreover, we prioritize the safe and timely delivery of your products while offering cost-effective shipping solutions.`,
  },
];
function Experties() {
  const renderData = data.map(({ id, image, heading, description, subDesc = null }) => (
    <Grid item xs={12} md={6} lg={4} sx={{ mb: 2 }} key={id}>
      <CardWithImage image={image} heading={heading} description={description} subDesc={subDesc} />
    </Grid>
  ));
  return (
    <MKBox component="section">
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            Experties & Capabilities
          </MKTypography>
          <MKTypography variant="body1" color="text" mt={2} style={{ "text-align": "jutify" }}>
            Elite precision machining works is your trusted partner for precision machining. Our
            team of skilled professionals, combined with cutting-edge technology, ensures the
            highest quality standards in every project. Our workahop boasts three key machines for
            precision machining.
          </MKTypography>
        </Grid>
        {/* <Grid container alignItems="center">
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h2">Experties & Capabilities</MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} mt={{ xs: 2, md: 2, lg: 0 }}>
            <MKTypography variant="body2" color="text">
              Elite precision machining works is your trusted partner for precision machining. Our
              team of skilled professionals, combined with cutting-edge technology, ensures the
              highest quality standards in every project. Our workahop boasts three key machines for
              precision machining.
            </MKTypography>
          </Grid>
        </Grid> */}
        <Grid container spacing={6} my="auto">
          {renderData}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Experties;
