// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
// import bgImage from "assets/images/bg-presentation.jpg";

function Intro() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 6 }}>
      <Container>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h2" my={1}>
              Precision Machining Service for Diverse Industries
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={7}>
            <MKTypography
              component="p"
              variant="subtitle1"
              color="text"
              style={{ "text-align": "justify", "font-weight": "400" }}
            >
              We provide high-quality precision machining services to meet the unique needs of the
              business across various industries. Our team of experts utilizes advanced technology
              and tachniques to deliver exceptional results.
              {/* - Repeatable and Fast helps Low Volume Engineering <br />
              - Offers Detailed and High Tolerance Specifications <br />
              - Wide Range of Production <br />- Provides Great Surface Finishing */}
            </MKTypography>
          </Grid>
          {/* <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3} justifyContent={"space-evenly"} py={{ xs: 3, md: 3 }}>
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  borderRadius: "16px",
                  backgroundImage: `url(${bgImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  height: "35vh",
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  borderRadius: "16px",
                  backgroundImage: `url(${bgImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  height: "35vh",
                }}
              ></Grid>
            </Grid>
            <Grid container spacing={3} justifyContent={"space-evenly"} py={{ xs: 3, md: 3 }}>
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  borderRadius: "16px",
                  backgroundImage: `url(${bgImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  height: "35vh",
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  borderRadius: "16px",
                  backgroundImage: `url(${bgImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  height: "35vh",
                }}
              ></Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Intro;
