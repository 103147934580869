// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/images/about_us.jpg";
import Quality from "./section/Quality";
import About from "./section/About";
import Header from "pages/Header";
import Footer from "pages/Footer";

function AboutUs() {
  return (
    <>
      <Header />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <About />
        <Quality />
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
}

export default AboutUs;
