// Material Kit 2 React components
import MKBox from "components/MKBox";
import ContactCard from "./section/ContactCard";
import GoogleLocation from "./section/GoogleLocation";

// Images
import bgImage from "assets/images/contact_us_4.jpg";
import Header from "pages/Header";
import Footer from "pages/Footer";

function ContactUs() {
  return (
    <>
      <Header />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <ContactCard />
      </MKBox>
      <GoogleLocation />
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
}

export default ContactUs;
