// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/images/home_page_top.jpg";
import Experties from "./sections/Experties";
import Intro from "./sections/Intro";
import WhyUs from "./sections/WhyUs";
import OurWork from "./sections/OurWork";
import Header from "pages/Header";
import Footer from "pages/Footer";

function Home() {
  return (
    <>
      <Header />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      ></MKBox>
      <MKBox bgColor="white">
        <Intro />
        <WhyUs />
        <Experties />
        <OurWork />
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
}

export default Home;
