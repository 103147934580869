// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import bgImage4a from "assets/images/about_us_g4_1.jpg";
import bgImage4b from "assets/images/about_us_g4_2.jpg";
import bgImage4c from "assets/images/about_us_g4_3.jpg";
import bgImage4d from "assets/images/about_us_g4_4.jpg";
import bgImage5 from "assets/images/about_us_last.jpg";

function Quality() {
  return (
    <MKBox component="section">
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 2, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            Quality Policy
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={12}
          alignItems="center"
          sx={{ textAlign: "center", mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="body1" color="text" mt={4}>
            EPMW is committed to delivering exceptional quality precision machining services that
            consistently exceed customer expectations through continuous improvement, employee
            involvement, and a preventive approach
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 8 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid container item xs={12} lg={6} spacing={3}>
            <Grid item>
              <img src={bgImage4a} height={250} width={250} style={{ borderRadius: "16px" }}></img>
            </Grid>
            <Grid item>
              <img src={bgImage4b} height={250} width={250} style={{ borderRadius: "16px" }}></img>
            </Grid>
            <Grid item>
              <img src={bgImage4c} height={250} width={250} style={{ borderRadius: "16px" }}></img>
            </Grid>
            <Grid item>
              <img src={bgImage4d} height={250} width={250} style={{ borderRadius: "16px" }}></img>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKTypography
              component="p"
              variant="h5"
              color="text"
              style={{ "text-align": "justify" }}
            >
              1. Commitment to Quality
            </MKTypography>
            <MKTypography
              component="p"
              variant="body2"
              color="text"
              style={{ "text-align": "justify" }}
              py={2}
            >
              Our Company is dedicated to providing exceptional quality precision machining services
              that consistently exceed customer expectations. We are committed to continuous
              improvement in all aspects of our operations, ensuring that we remain a leader in the
              precision machining industry. Our team of highly skilled and experienced professionals
              is dedicated to upholding the highest standards of quality throughout the entire
              production process.
            </MKTypography>
            <MKTypography
              component="p"
              variant="h5"
              color="text"
              style={{ "text-align": "justify", paddingTop: "24px" }}
            >
              2. Customer Focus
            </MKTypography>
            <MKTypography
              component="p"
              variant="body2"
              color="text"
              style={{ "text-align": "justify" }}
              py={2}
            >
              At EPMW, we place the utmost importance on understanding and addressing the specific
              needs of our customers. We work closely with our clients to develop tailored solutions
              that meet their unique requirements. Our commitment to customer satisfaction extends
              to providing accurate and timely information about our products and services, ensuring
              that our customers are well-informed throughout the entire engagement. We value
              customer feedback and concerns, actively seeking opportunities to improve our
              products, services, and overall customer experience.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: 8 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid container item xs={12} lg={6} spacing={3}>
            <Grid item lg={12}>
              <MKTypography
                component="p"
                variant="h5"
                color="text"
                style={{ "text-align": "justify" }}
              >
                Unwavering Commitment to Excellence
              </MKTypography>
            </Grid>
            <Grid item lg={6}>
              <MKTypography
                component="p"
                variant="h6"
                color="text"
                style={{ "text-align": "justify" }}
              >
                Careful Job Placement:
              </MKTypography>
              <MKTypography
                component="p"
                variant="button"
                color="text"
                style={{ "text-align": "justify" }}
                py={1}
              >
                We meticulously assign each job order to the most qualified team members, ensuring
                that every project receives the expertise and attention it deserves. Our rigorous
                project management system ensures that deadlines are met and customer expectations
                are exceeded.
              </MKTypography>
            </Grid>
            <Grid item lg={6}>
              <MKTypography
                component="p"
                variant="h6"
                color="text"
                style={{ "text-align": "justify" }}
              >
                High-End Tooling and Instruments:
              </MKTypography>
              <MKTypography
                component="p"
                variant="button"
                color="text"
                style={{ "text-align": "justify" }}
                py={1}
              >
                We employ a comprehensive range of high-precision tools and measuring instruments,
                including vernier calipers, micrometers, dial gauges, and digital meters, to
                guarantee the utmost accuracy and consistency in our work.
              </MKTypography>
            </Grid>
            <Grid item lg={6}>
              <MKTypography
                component="p"
                variant="h6"
                color="text"
                style={{ "text-align": "justify" }}
              >
                Pristine Shipping:
              </MKTypography>
              <MKTypography
                component="p"
                variant="button"
                color="text"
                style={{ "text-align": "justify" }}
                py={1}
              >
                We take the utmost care in packaging and shipping our products, utilizing protective
                materials and methods to ensure that every component arrives in pristine condition,
                ready for seamless integration into your projects.
              </MKTypography>
            </Grid>
            <Grid item lg={6}>
              <MKTypography
                component="p"
                variant="h6"
                color="text"
                style={{ "text-align": "justify" }}
              >
                Continuous Innovation:
              </MKTypography>
              <MKTypography
                component="p"
                variant="button"
                color="text"
                style={{ "text-align": "justify" }}
                py={1}
              >
                We continuously invest in advanced technologies, training programs, and process
                improvements to maintain our competitive edge and deliver innovative solutions that
                meet the evolving needs of our customers.
              </MKTypography>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <img src={bgImage5} height={500} width={500} style={{ borderRadius: "22px" }}></img>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Quality;
