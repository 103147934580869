// @mui icons
import EmailIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logos/apple-touch-icon.png";
import MKBox from "components/MKBox";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Elite Precision Machining Works",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <WhatsAppIcon />,
      link: "https://wa.me/918735966424",
    },
    {
      icon: <EmailIcon />,
      link: "mailto:epmworks.offical@gmail.com",
    },
  ],
  menus: [
    {
      name: "Get In Touch",
      items: [],
      info: [
        {
          name: "address",
          details:
            "899/ 3B, G.I.D.C Industrial Estate, Makarpura, Vadodara - 390010, Gujarat, India.",
          icon: "fas fa-map-marker-alt",
        },
        { name: "email", details: "epmworks.offical@gmail.com", icon: "fas fa-envelope" },
        {
          name: "phone1",
          details: `(+91) 8735966424`,
          icon: "fas fa-phone",
        },
        {
          name: "phone2",
          details: `(+91) 8603911712`,
          icon: "fas fa-phone",
        },
      ],
    },
    {
      name: "company",
      items: [
        { name: "about us", route: "/about-us" },
        { name: "contact us", route: "/contact-us" },
        { name: "certificates", route: "/certificates" },
      ],
    },
    // {
    //   name: "legal",
    //   items: [
    //     { name: "terms & conditions", href: "#" },
    //     { name: "privacy policy", href: "#" },
    //   ],
    // },
  ],
  copyright: (
    <MKBox display="flex" justifyContent="center" flexDirection="column">
      <MKTypography variant="button" fontWeight="regular">
        All rights reserved. Copyright &copy; {date} Elite Precision.
      </MKTypography>
      <MKTypography
        component="a"
        href="#"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Designed by: DP Tech
      </MKTypography>
    </MKBox>
  ),
};
