// Material Kit 2 React components
import MKBox from "components/MKBox";

function GoogleLocation() {
  return (
    <MKBox>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.8037902278693!2d73.18548687603528!3d22.247522144805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc50f7bc6a205%3A0x4e66406089b45ccd!2sGidc%20Industrial%20Estate!5e0!3m2!1sen!2sin!4v1704631273252!5m2!1sen!2sin"
        width="100%"
        height="400"
        style={{ border: "10px" }}
        allowfullscreen="true"
        loading="lazy"
      ></iframe>
    </MKBox>
  );
}

export default GoogleLocation;
