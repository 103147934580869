// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ImageCard({ image, heading, pdf }) {
  const imageTemplate = <MKBox component="img" src={image} width="100%" p={1} />;

  const openPDF = (file) => {
    const aTag = document.createElement("a");
    aTag.href = file;
    aTag.target = "_blank";
    aTag.click();
  };

  return (
    <MKBox
      position="relative"
      bgColor="white"
      borderRadius="xl"
      shadow="md"
      sx={{
        boxShadow:
          "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.3), 0rem 0.125rem 1.25rem -0.0625rem rgba(0, 0, 0, 0.3)",
        overflow: "hidden",
        transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
        transformOrigin: "50% 0",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",
        transition: "transform 200ms ease-out",

        "&:hover": {
          transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
        },
      }}
      onClick={() => openPDF(pdf)}
    >
      {image && imageTemplate}
      {heading && (
        <MKBox lineHeight={1} mx={2} mb={2} style={{ textAlign: "center" }}>
          <MKTypography variant="h6" fontWeight="bold">
            {heading}
          </MKTypography>
        </MKBox>
      )}
    </MKBox>
  );
}

// Setting default props for the ExampleCard
ImageCard.defaultProps = {
  image: "",
  heading: "",
  pdf: "",
};

// Typechecking props for the ExampleCard
ImageCard.propTypes = {
  image: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  pdf: PropTypes.string.isRequired,
};

export default ImageCard;
