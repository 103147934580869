// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React examples
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import MKTypography from "components/MKTypography";
const data = [
  {
    id: 1,
    icon: "handshake",
    title: "Integrity",
    description: `We uphold ethical and honest business practices, building trust with our customers and partners. We operate with transparency and accountability, ensuring that our customers are always informed and engaged.`,
  },
  {
    id: 2,
    icon: "star",
    title: "Excellence",
    description: `We utilize state-of-the-art equipment and technology, combined with the expertise of our highly skilled team, to produce high-precision components to your exact specifications, seeking new ways to enhance our processes and deliver even better results.`,
  },
  {
    id: 3,
    icon: "gpp_good",
    title: "Quality",
    description: `Our rigorous quality control measures ensure that every component meets customer specifications. We employ a variety of high-precision tools and measuring instruments to guarantee the utmost accuracy and consistency in our work.`,
  },
  {
    id: 4,
    icon: "paid",
    title: "Competitive rates",
    description: `We offer competitive rates for our precision machining services, without compromising on quality or service. We are committed to providing value for money, ensuring that our customers receive the best possible service and quality at a competitive price.`,
  },
];
function WhyUs() {
  const renderData = data.map(({ id, icon, title, description }) => (
    <Grid item xs={12} md={6} lg={3} key={id}>
      <FilledInfoCard color="info" icon={icon} title={title} description={description} />
    </Grid>
  ));
  return (
    <Container>
      <Grid
        container
        item
        xs={12}
        lg={12}
        flexDirection="column"
        alignItems="center"
        sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
      >
        <MKTypography variant="h2" fontWeight="bold">
          Why To Choose Us?
        </MKTypography>
        {/* <MKTypography variant="body1" color="text" mt={2} style={{ "text-align": "jutify" }}>
          Elite precision machining works is your trusted partner for precision machining. Our team
          of skilled professionals, combined with cutting-edge technology, ensures the highest
          quality standards in every project. Our workahop boasts three key machines for precision
          machining.
        </MKTypography> */}
      </Grid>
      <Grid container spacing={3}>
        {renderData}
      </Grid>
    </Container>
  );
}
export default WhyUs;
