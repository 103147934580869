// Material Kit 2 React components
import MKBox from "components/MKBox";
import { Grid } from "@mui/material";

// Images
import bgImage from "assets/images/certificates_main.jpg";
import Header from "pages/Header";
import Footer from "pages/Footer";
import Container from "@mui/material/Container";
import isoCerti from "assets/images/certificates/iso_9001_certifacte.jpg";
import isoCertiPdf from "assets/images/certificates/pdfs/iso_9001_certifacte.pdf";
import zedCerti from "assets/images/certificates/zed_certificate.jpg";
import zedCertiPdf from "assets/images/certificates/pdfs/zed_certificate.pdf";
import ImageCard from "./components/ImageCard";

const data = [
  { id: 1, img: isoCerti, heading: "ISO 9001: 2015", pdf: isoCertiPdf },
  { id: 2, img: zedCerti, heading: "Zed Bronze", pdf: zedCertiPdf },
];
function Certificates() {
  const renderData = data.map(({ id, img, heading, pdf }) => (
    <Grid item xs={12} md={6} lg={4} sx={{ mb: 2 }} key={id}>
      <ImageCard image={img} heading={heading} pdf={pdf} />
    </Grid>
  ));
  return (
    <>
      <Header />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.4),
                rgba(gradients.dark.state, 0.4)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
      </MKBox>
      <MKBox component="section" py={{ xs: 4, lg: 6 }} bgColor="white">
        <Container>
          <Grid container item justifyContent={"center"} spacing={4}>
            {renderData}
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
}

export default Certificates;
