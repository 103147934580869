// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import QuiltedImageList from "../components/QuiltedImageList";

// Images
// import bgImage from "assets/images/bg-presentation.jpg";
// const bgImage =
//   "https://images.unsplash.com/photo-1481349518771-20055b2a7b24?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cmFuZG9tfGVufDB8fDB8fHww";

function OurWork() {
  return (
    <>
      <MKBox component="section" py={2}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
          >
            <MKTypography variant="h2" fontWeight="bold">
              Our Quality Components
            </MKTypography>
          </Grid>
          <QuiltedImageList></QuiltedImageList>
        </Container>
      </MKBox>
    </>
  );
}
export default OurWork;
