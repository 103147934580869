// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

function Footer() {
  return (
    <>
      <DefaultFooter content={footerRoutes} />
    </>
  );
}
export default Footer;
