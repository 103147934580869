// @mui material components
import Icon from "@mui/material/Icon";

const routes = [
  {
    name: "Home",
    icon: <Icon>home</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    route: "/home",
  },
  {
    name: "About Us",
    icon: <Icon>info</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    route: "/about-us",
  },
  {
    name: "Certificates",
    icon: <i className="fas fa-medal" />,
    columns: 1,
    rowsPerColumn: 2,
    route: "/certificates",
  },
];

export default routes;
